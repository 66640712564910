import React from 'react'

const Social = () => {
  return (
    <div className="home__social">
        <a href="https://www.instagram.com/mianarsalan20/" className="home__social-icon" target="_blank">
            <i className="uil uil-instagram"></i>
        </a>

        <a href="https://www.linkedin.com/in/mianarsalan20/" className="home__social-icon" target="_blank">
            <i className="uil uil-linkedin-alt"></i>
        </a>

        <a href="mailto:mianarsalan20@gmail.com" className="home__social-icon" target="_blank">
            <i className="uil uil-envelope-alt"></i>
        </a>

        <a href="https://github.com/mianarsalan20" className="home__social-icon" target="_blank">
            <i className="uil uil-github-alt"></i>
        </a>

    </div>
  )
}

export default Social
